import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

export const mahfilhoApi=createApi({
    reducerPath: 'mahfilhoApi',
    tagTypes: 'Mahfilho',
    baseQuery: fetchBaseQuery({
        baseUrl: `https://api.upshift.colibri.tj/api/`,
        prepareHeaders: (headers ) => {
            const token = JSON.parse(localStorage.getItem('token'));
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      headers.set('Accept', 'application/json')
      return headers;
        }
    }),
    endpoints: build => ({
        getMahfilho: build.query({
            query:(locationId)=> `report/table1${locationId ? `?cityId=${locationId}` : ''}`, 
        }),

        postMahfilho: build.mutation ({
            query: (data) => ({
                url: `report/table1`,
                method: "POST",
                body: data

            })
        }) 

    })

})

export const { useGetMahfilhoQuery, usePostMahfilhoMutation } = mahfilhoApi
