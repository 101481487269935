import {Fragment, useEffect, useState} from "react";
import {Dialog, Transition, Disclosure} from "@headlessui/react";
import {Bars3Icon, XMarkIcon} from "@heroicons/react/24/outline";
import {ChevronRightIcon} from "@heroicons/react/20/solid";
import {ReactComponent as HomeIcon} from "../assets/icons/home-icon.svg";
import {ReactComponent as UsersIcon} from "../assets/icons/users-icon.svg";
import {ReactComponent as ReportsIcon} from "../assets/icons/reports-icon.svg";
import {ReactComponent as CdoIcon} from "../assets/icons/cdo-icon.svg";
import {ReactComponent as PartnersIcon} from "../assets/icons/partners-icon.svg";
import {ReactComponent as MembersIcon} from "../assets/icons/members-icon.svg";
import {ReactComponent as ProfileIcon} from "../assets/icons/profile-icon.svg";
import {ReactComponent as ProjectIcon} from "../assets/icons/project-icon.svg";
import {ReactComponent as CoursesIcon} from "../assets/icons/courses-icon.svg";
import {ReactComponent as RolesIcon} from "../assets/icons/roles-icon.svg";
import {ReactComponent as SubjectsIcon} from "../assets/icons/subjects-icon.svg";
import {ReactComponent as DirectiosnIcon} from "../assets/icons/directions-icon.svg";
import {Link, Outlet, useLocation} from "react-router-dom";
import primaryLogo from "../assets/logo/primaryLogo.svg";
import HeaderDropdown from "../components/headerDropdown/HeaderDropdown";


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const RootLayout = () => {
  const [roles, setRoles] = useState([])

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    setRoles(user?.permissions)
  }, [])

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const location = useLocation();

  const navigation = [
    {name: "Главное", href: "/", icon: HomeIcon, permission: 'index-main'},
    {name: "Проекты", href: "/projects", icon: ProjectIcon, permission: 'index-project'},
    {name: "Группы", href: "/courses", icon: CoursesIcon, permission: 'index-group'},
    {name: "Сотрудники", href: "/staff", icon: UsersIcon, permission: 'index-users'},
    {name: "Участники", href: "/members", icon: MembersIcon, permission: 'index-members'},
    {name: "ЦДО", href: "/cdo", icon: CdoIcon, permission: 'index-pec'},
    {name: "Партнеры", href: "/partners", icon: PartnersIcon, permission: 'index-partner'},
    {name: "Направления", href: "/directions", icon: DirectiosnIcon, permission: 'index-direction'},
    {name: "Предметы", href: "/subjects", icon: SubjectsIcon, permission: 'index-subject'},
    {name: "Роли и доступы", href: "/roles", icon: RolesIcon, permission: 'index-roles'},
    {
      name: "Отчёты",
      href: "/reports",
      permission: 'index-report',
      icon: ReportsIcon,
      children: [
        {name: "По городам", href: "/city-reports"},
        {name: "По направлениям", href: "/cluster-reports"},
        {name: "По предметам", href: "/subject-reports", permission: ''},
      ],
    },
    {name: "Профиль", href: "/profile", icon: ProfileIcon},

    {
      name: "Хисобот",
      permission: 'index-group',
      icon: ReportsIcon,
      children: [
        {name: "Муассисаҳо, маҳфилҳо", href: "/mahfilho", permission: 'index-group'},
        {name: "Кормандони омўзгорӣ", href: "/kormandon", permission: 'index-group'},
        {name: "Сайёҳӣ ва лагерҳо", href: "/sayohi", permission: 'index-group'},
        {name: "Заминаи моддӣ", href: "/modi", permission: 'index-group'},
      ],
    }
  ];

  return (
    <div className="flex flex-col">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80"/>
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 left-full flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-primary px-6 pb-4">
                  <div className="flex mt-5 items-center justify-between">
                    <Link
                      className="flex items-center justify-items-start w-full"
                      to="/"
                    >
                      <img className='max-w-full w-full h-auto' src={primaryLogo} alt="Upshift-logo"/>
                    </Link>
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul className="-mx-2 space-y-1">
                          {navigation?.map((item) => (
                            roles?.includes(item.permission) ? <li key={item.name}>
                              {!item.children ? (
                                <Link
                                  to={item.href}
                                  className={classNames(
                                    location.pathname === item.href
                                      ? "bg-secondary text-white"
                                      : "text-indigo-200 hover:text-white hover:bg-secondary",
                                    "group flex items-center gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      item.current
                                        ? "text-white"
                                        : "text-indigo-200 group-hover:text-white",
                                      "shrink-0"
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </Link>
                              ) : (
                                <Disclosure as="div">
                                  {({open}) => (
                                    <>
                                      <Disclosure.Button
                                        className={classNames(
                                          "flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-indigo-200 hover:text-white hover:bg-secondary"
                                        )}
                                      >
                                        <item.icon
                                          className="h-6 w-6 shrink-0 text-gray-400"
                                          aria-hidden="true"
                                        />
                                        {item.name}
                                        <ChevronRightIcon
                                          className={classNames(
                                            open
                                              ? "rotate-90 text-gray-500"
                                              : "text-gray-400",
                                            "ml-auto h-5 w-5 shrink-0"
                                          )}
                                          aria-hidden="true"
                                        />
                                      </Disclosure.Button>
                                      <Disclosure.Panel
                                        as="ul"
                                        className="mt-1 px-2 space-y-1"
                                      >
                                        {item.children.map((subItem) => (
                                          <li key={subItem.name}>
                                            <Link
                                              to={subItem.href}
                                              className={classNames(
                                                location.pathname === subItem.href
                                                  ? "bg-secondary text-white"
                                                  : "text-indigo-200 hover:text-white hover:bg-secondary",
                                                "group flex items-center gap-x-3 rounded-md py-2 text-sm font-medium pl-10"
                                              )}
                                            >
                                              {subItem.name}
                                            </Link>
                                          </li>
                                        ))}
                                      </Disclosure.Panel>
                                    </>
                                  )}
                                </Disclosure>
                              )}
                            </li> : item.name === 'Профиль' && <li key={item.name}>
                              <Link
                                to={item.href}
                                className={classNames(
                                  location.pathname === item.href
                                    ? "bg-secondary text-white"
                                    : "text-indigo-200 hover:text-white hover:bg-secondary",
                                  "group flex items-center gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                )}
                              >
                                <item.icon
                                  className={classNames(
                                    item.current
                                      ? "text-white"
                                      : "text-indigo-200 group-hover:text-white",
                                    "shrink-0"
                                  )}
                                  aria-hidden="true"
                                />
                                {item.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="flex h-screen">
        <div className="hidden lg:flex lg:w-[256px] lg:flex-col">
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-primary px-6 pb-4">
            <div>
              <Link className="flex mt-5 items-center justify-between" to="/">
                <img className='max-w-full w-full h-auto' src={primaryLogo} alt="Upshift-logo"/>
              </Link>
            </div>
            <nav className="flex flex-1 flex-col">
              <ul className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul className="-mx-2 space-y-1">
                    {navigation?.map((item) => (
                      roles?.includes(item.permission) ? <li key={item.name}>
                        {!item.children ? (
                          <Link
                            to={item.href}
                            className={classNames(
                              location.pathname === item.href
                                ? "bg-secondary text-white"
                                : "text-indigo-200 hover:text-white hover:bg-secondary",
                              "group flex items-center gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                            )}
                          >
                            <item.icon
                              className={classNames(
                                item.current
                                  ? "text-white"
                                  : "text-indigo-200 group-hover:text-white",
                                "shrink-0"
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </Link>
                        ) : (
                          <Disclosure as="div">
                            {({open}) => (
                              <>
                                <Disclosure.Button
                                  className={classNames(
                                    "flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-indigo-200 hover:text-white hover:bg-secondary"
                                  )}
                                >
                                  <item.icon
                                    className="h-6 w-6 shrink-0 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                  <ChevronRightIcon
                                    className={classNames(
                                      open
                                        ? "rotate-90 text-gray-500"
                                        : "text-gray-400",
                                      "ml-auto h-5 w-5 shrink-0"
                                    )}
                                    aria-hidden="true"
                                  />
                                </Disclosure.Button>
                                <Disclosure.Panel
                                  as="ul"
                                  className="mt-1 px-2 space-y-1"
                                >
                                  {item.children.map((subItem) => (
                                    <li key={subItem.name}>
                                      <Link
                                        to={subItem.href}
                                        className={classNames(
                                          location.pathname === subItem.href
                                            ? "bg-secondary text-white"
                                            : "text-indigo-200 hover:text-white hover:bg-secondary",
                                          "group flex items-center gap-x-3 rounded-md py-2 text-sm font-medium pl-10"
                                        )}
                                      >
                                        {subItem.name}
                                      </Link>
                                    </li>
                                  ))}
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        )}
                      </li> : item.name === 'Профиль' && <li key={item.name}>
                        <Link
                          to={item.href}
                          className={classNames(
                            location.pathname === item.href
                              ? "bg-secondary text-white"
                              : "text-indigo-200 hover:text-white hover:bg-secondary",
                            "group flex items-center gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-white"
                                : "text-indigo-200 group-hover:text-white",
                              "shrink-0"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="flex flex-col flex-1">
          <div className="flex flex-col h-full">
            <div
              className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6">
              <button
                type="button"
                className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true"/>
              </button>

              <div
                className="h-6 w-px bg-gray-900/10 lg:hidden"
                aria-hidden="true"
              />

              <div className="flex flex-1 justify-end">
                <HeaderDropdown/>
              </div>
            </div>

            <main className="h-full bg-back pt-6 pb-11 px-4 sm:px-4 overflow-x-hidden">
              <Outlet/>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RootLayout;
