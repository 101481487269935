import React, { useState, useEffect } from "react";
import { Tab, TabList, Tabs, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { FormProvider, useForm } from "react-hook-form";
import {
  useGetSayohiQuery,
  usePostSayohiMutation,
} from "../../redux/api/sayohiApi";
import LabelInputContainer from "../../components/inputs/LabelInputContainer";
import { parseInt } from "lodash";
import { SubmitModal } from "../mahfilho/SubmitModal";
import LocationsCP from "../ui/LocationsCP";
import * as XLSX from "xlsx";

const labels = [
  "Ҳамагӣ (ҷамъи сатрҳои 02-09)",
  "Марказҳои кудакону наврасон",
  "Cтансияҳои техникони ҷавон",
  "Стансияи натуралистони ҷавон",
  "Стансияи сайёҳони ҷавон",
  "Базаҳои мустақили туристӣ",
  "Боғи истироҳати барои кӯдакон",
  "Майдончаҳои кӯдакона",
  "Дигар муассисаҳои берун аз мактабӣ",
];

/*
  /States
*/
const tabNames = [
  "Миқдори муассисаҳои берун аз мактабӣ Миқдори муассисаҳое, ки дорои замина барои маскангирифтани кўдакон - сайёҳон дар муассисава ё дар дигар мавқе ҷойгиршуда",
  "Миқдори ҷой барои кўдакон - сайёҳон дар заминаи ин муассисаҳо",
  "Шумораи кўдакон-сайёҳоне, ки дар давоми сол дар заминаи худи муассисава ё заминаи дар дигарҷой ба онҳо хизматрасонида мешавад",
  "Шумораи кўдакони дар соли ҳисоботи иштирокнамуда дар экскурсия",
  "Шумораи кўдакони дар соли ҳисоботи иштирокнамуда дар саёҳат",
  "Миқдори лагерҳои соҳавӣ",
  "шумораи кормандон - хизматчиён барои кўдакон дар давоми сол",
  "Гушаи зидди сўхтор",
];

function stringifyValues(obj) {
  return Object.keys(obj).reduce((newObj, key) => {
    newObj[key] = JSON.stringify(obj[key]);
    return newObj;
  }, {});
}

export default function Sayohi() {
  const user = JSON.parse(localStorage.getItem("user"));
  const role = user.role[0];
  /*
    States
  */
  const [tabs, setTabs] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [tabsObject, setTabsObject] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [locationId, setLocationId] = useState("");

  const methods = useForm();
  const { data, isLoading, refetch } = useGetSayohiQuery(locationId || "");
  const [postSayohi, { isLoading: load }] = usePostSayohiMutation();

  useEffect(() => {
    if (!isLoading) {
      setLoading(true);
      const result = {};
      const newObj = JSON.parse(JSON.stringify(data[0]));
      delete newObj?.created_at;
      delete newObj?.id;
      delete newObj?.status;
      delete newObj?.updated_at;
      delete newObj?.user_id;
      Object.keys(newObj).forEach((key) => {
        result[key] = JSON.parse(newObj[key]);
      });

      const zerosArray = new Array(labels.length).fill(0);

      for (let key in result) {
        if (result[key] === null) {
          result[key] = [...zerosArray]; // Создаем копию массива с нулями
        }
      }
      const sumObj = {};
      for (const key in result) {
        const array = result[key];
        const sum = array.slice(1).reduce((acc, val) => {
          return acc + (val ? parseInt(val, 10) : 0);
        }, 0);

        array[0] = `${sum}`;

        sumObj[key] = array;
      }
      const newStructure = Object.keys(sumObj).map((key, index) => {
        const children = sumObj[key].map((value, childIndex) => ({
          id: childIndex + 1,
          label: labels[childIndex] || `Child ${childIndex + 1}`,
          value,
        }));

        return {
          id: index + 1,
          title: `${index + 1}`,
          children,
          description: tabNames[index] || `Description ${index + 1}`,
        };
      });
      setTabsObject(newStructure);
      setLoading(false);
    }
  }, [isLoading, data, refetch]);

  useEffect(() => {
    if (data) {
      setSubmitted(data[0]?.status === "submitted");
    }
  }, [data]);

  const onSubmit = async (e) => {
    e.preventDefault();
    let payload = {};
    let values = tabs.map((el) => el.children.map((item) => item.value));
    values.map((val, i) => {
      payload[`tab${i + 1}`] = val;
    });
    payload.status = 1;
    const newPayload = stringifyValues(payload);
    await postSayohi({ ...newPayload }).unwrap();
    refetch();
    window.location.reload();
  };

  const onRegisterForm = async (e) => {
    e.preventDefault();
    let payload = {};
    let values = tabs.map((el) => el.children.map((item) => item.value));
    values.map((val, i) => {
      payload[`tab${i + 1}`] = val;
    });
    payload.status = 2;
    const newPayload = stringifyValues(payload);
    await postSayohi({ ...newPayload }).unwrap();
    refetch();
    window.location.reload();
  };

  const generateTabs = (data) => {
    const tempTabs = [];
    const keys = Object.keys(data[0]).filter((key) => key.includes("tab"));
    keys.forEach((el, index) => {
      let currentVal = data[0][el] ? JSON.parse(data[0][el]) : [];
      tempTabs.push({
        id: index + 1,
        title: `${index + 1}`,
        children: labels.map((label, i) => {
          return {
            id: i + 1,
            label: label,
            value: currentVal[i] !== null ? currentVal[i] : "",
          };
        }),
      });
      tempTabs.forEach((tab, i) => {
        tabNames.forEach((name, index) => {
          if (i === index) {
            tab.description = name;
          }
        });
      });
    });
    setTabs(tempTabs);
  };
  const setFormTabVal = (val, parent, childId) => {
    let tempTabs = tabs;
    tempTabs
      .find((tab) => tab.id == parent.id)
      .children.find((el) => el.id == childId).value = val;
    setTabs(tempTabs);
  };

  const nextTab = () => {
    const tabsQty =
      Object.keys(data)?.filter((key) => key.includes("tab")).length | 0;
    if (tabIndex + 1 < tabsQty) setTabIndex(tabIndex + 1);
    if (tabIndex + 1 === tabsQty) setTabIndex(0);
  };

  useEffect(() => {
    if (data) generateTabs(data);
  }, [data]);
  if (isLoading || load || loading) {
    return <p> Loading</p>;
  }

   const exportToExcel = () => {
     const formattedData = tabsObject.map((tab) => {
       return {
         Tab: tab.title,
         Description: tab.description,
         ...Object.fromEntries(
           tab.children.map((child) => [child.label, child.value])
         ),
       };
     });

     const worksheet = XLSX.utils.json_to_sheet(formattedData);
     const workbook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(workbook, worksheet, "Sayohi Data");

     XLSX.writeFile(workbook, "SayohiData.xlsx");
   };

  return (
    <FormProvider {...methods}>
      <form className="mx-5" onSubmit={onSubmit}>
        <Tabs
          className="relative"
          selectedIndex={tabIndex}
          onSelect={(index) => setTabIndex(index)}
        >
          <TabList>
            {tabs.map((tab) => (
              <Tab key={tab.id}>{tab.title}</Tab>
            ))}
          </TabList>

          {tabsObject.map((tab) => (
            <TabPanel key={tab.id}>
              <p className="mt-8 lg:w-[80%]">{tab.description}</p>
              <LocationsCP
                locationId={locationId}
                setLocationId={setLocationId}
              />

              <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
                {tab.children.map((child) => (
                  <LabelInputContainer
                    disabled={submitted}
                    setValue={setFormTabVal}
                    key={child.id}
                    label={child.label}
                    value={child}
                    parent={tab}
                    locationId={locationId}
                  />
                ))}
              </div>
            </TabPanel>
          ))}
        </Tabs>
        <div className="flex justify-between items-center">
          <div className="flex item-center justify-center gap-4">
            {role === "Республиканский ЦДО" ||
            role === "Администратор" ? null : (
              <button
                disabled={submitted}
                type="submit"
                className={
                  submitted
                    ? "opacity-50 mt-5 inline-flex justify-center rounded-md bg-primary px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
                    : "mt-5 inline-flex justify-center rounded-md bg-primary px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
                }
              >
                Сохранить
              </button>
            )}
            {role === "Республиканский ЦДО" ||
            role === "Администратор" ? null : (
              <button
                disabled={submitted}
                onClick={() => setOpen(true)}
                type="button"
                className={
                  submitted
                    ? "opacity-50 mt-5 inline-flex justify-center rounded-md bg-primary px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
                    : "mt-5 inline-flex justify-center rounded-md bg-primary px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
                }
              >
                Отправить форму
              </button>
            )}
          </div>
          <div className="flex gap-4">
            <button
              type="button"
              onClick={exportToExcel}
              className="mt-5 inline-flex justify-center border border-primary px-4 py-2 rounded-md text-primary hover:bg-secondary hover:text-white"
            >
              Экспорт в Excel
            </button>
            <button
              type="button"
              className=" mt-5 inline-flex justify-center  border border-primary px-3 py-2 rounded-md text-primary hover:bg-secondary hover:text-white"
              onClick={() => nextTab()}
            >
              Далее
            </button>
          </div>
        </div>
      </form>
      <SubmitModal open={open} setOpen={setOpen} onSubmit={onRegisterForm} />
    </FormProvider>
  );
}
