import {useState, useEffect, useCallback, useContext} from "react";
import Pagination from "../../components/pagination/Pagination";
import {ReactComponent as MagnifyingGlassIcon} from "../../assets/icons/search-icon.svg";
import CdosTable from "../../components/tables/cdosTable/CdosTable";
import Modal from "../../components/modals/modal/Modal";
import {ReactComponent as PlusIcon} from "../../assets/icons/plus-icon.svg";
import AuthContext from "../../context/AuthProvider";
import axiosInstance from "../../api/axios";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Cdos = () => {
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [cdo, setCdo] = useState({});
  const [cdos, setCdos] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [totalCdos, setTotalCdos] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false)
  const [roles, setRoles] = useState([])

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    setRoles(user.permissions)
  }, [])

  const {token} = useContext(AuthContext);

  const getCdosByPage = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(`/pecs?page=${currentPage}`, {
        headers: {Authorization: `Bearer ${token}`},
      });
      setTimeout(() => {
        setCdos(response?.data?.data);
        setTotalCdos(response?.data?.meta?.total);
        setFrom(response?.data?.meta?.from);
        setTo(response?.data?.meta?.to);
        setIsLoading(false);
      }, 300);
    } catch (error) {
      if (error.response?.status === 401 || error.code === "ERR_NETWORK") {
        localStorage.removeItem("token");
        window.location.reload();
      }
    }
  }, [currentPage, token]);

  useEffect(() => {
    getCdosByPage();
  }, [getCdosByPage]);

  const updateCdo = async (data, id) => {
    try {
      setLoading(true)
      await axiosInstance.put(`pecs/${id}`, JSON.stringify(data), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setOpen(false);
      getCdosByPage();
      setLoading(false);

    } catch (error) {
      if (error.response?.status === 401 || error.code === "ERR_NETWORK") {
        localStorage.removeItem("token");
        window.location.reload();
        setLoading(false);

      }
    }
  };

  const addCdo = async (data) => {
    try {
      setLoading(true)
      await axiosInstance.post("/pecs", JSON.stringify(data), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setOpen(false);
      setIsLoading(true);
      getCdosByPage();
      setLoading(false)
    } catch (error) {
      if (error.response?.status === 401 || error.code === "ERR_NETWORK") {
        localStorage.removeItem("token");
        window.location.reload();

      }
      setLoading(false)
    }
  };

  const handleSearch = async (value) => {
    setTotalCdos(0);
    if (value.length >= 2) {
      try {
        const response = await axiosInstance.get(`/api/search-cdo/${value}`, {
          headers: {Authorization: `Bearer ${token}`},
        });
        if (response.data.data.length === 0) {
        } else {
          setCdos(response.data.data);
        }
      } catch (error) {
        if (error.response?.status === 401 || error.code === "ERR_NETWORK") {
          localStorage.removeItem("token");
          window.location.reload();
        }
      }
    } else if (value.length === 0) {
      getCdosByPage();
    }
    return;
  };

  const handleAddBtn = () => {
    setCdo({
      id: "",
      name: "",
      location: "",
      address: "",
      status: "inactive",
    });
    setOpen(true);
    setIsEditing(false);
  };

  const handleEditBtn = (data) => {
    setCdo(data);
    setOpen(true);
    setIsEditing(true);
  };

  return (
    <>
      <h1 className="font-semibold text-primary text-2xl">ЦДО</h1>
      <div className="mt-4 flex gap-4 flex-1 mb-4">
        <div className="relative w-full">
          <label htmlFor="search-field" className="sr-only">
            Search
          </label>
          <input
            id="search-field"
            className="block h-full w-full border-0 py-3 pl-3.5 pr-11 text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-primary sm:text-sm focus:outline-none rounded-md"
            placeholder="По названию"
            type="search"
            name="search"
            onChange={(e) => handleSearch(e.target.value)}
          />
          <MagnifyingGlassIcon
            className="pointer-events-none absolute inset-y-0 right-0 mr-3.5 h-full w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
        {roles.includes('store-pec') &&
          <button
          onClick={handleAddBtn}
          className="flex items-center justify-center flex-1 py-[9px] px-4 gap-3 bg-primary rounded-md hover:bg-secondary"
        >
          <PlusIcon/>
          <span className="text-white text-base font-medium">Добавить</span>
        </button>}
        <Modal 
          handleSaveBtn={isEditing ? updateCdo : addCdo}
          formName="cdo"
          open={open}
          setOpen={setOpen}
          data={cdo}
          loading={loading}
        />
      </div>
      {!isLoading && cdos.length > 0 ? (
        <CdosTable roles={roles} cdos={cdos} handleEditBtn={handleEditBtn}/>
      ) : !isLoading && cdos.length === 0 ? (
        <p>Таблица пуста</p>
      ) : (
        <div className="flow-root">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <Skeleton height={40} count={10}/>
            </div>
          </div>
        </div>
      )}
      {totalCdos > 8 && (
        <Pagination
          totalPage={totalCdos / 8}
          setCurrentPage={setCurrentPage}
          totalObjects={totalCdos}
          to={to}
          from={from}
        />
      )}
    </>
  );
};

export default Cdos;
