import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {locations} from "../../hardCodedArrays/Arrays";

const CdoForm = ({setOpen, handleSaveBtn, data, loading}) => {


  const schema = yup.object().shape({
    name: yup.string().required("Поле не должно быть пустым"),
    city_id: yup.string().required("Поле не должно быть пустым"),
    address: yup.string().required("Поле не должно быть пустым"),
    status: yup.string().required("Поле не должно быть пустым"),
  });

  const {id, name, location, address, status} = data;
  // console.log('location:', location);
  console.log('data:', data);
  
  

  // function capitalizeFirstLetter(string) {
  //   return string.charAt(0).toUpperCase() + string.slice(1);
  // }

  // const locations = [
  //   "Душанбе",
  //   "Бохтар",
  //   "Вахдат",
  //   "Гиссар",
  //   "Гулистон",
  //   "Истаравшан",
  //   "Истиклол",
  //   "Исфара",
  //   "Канибадам",
  //   "Куляб",
  //   "Левакант",
  //   "Нурек",
  //   "Пенджикент",
  //   "Рогун",
  //   "Турсунзаде",
  //   "Худжанд",
  //   "Хорог",
  // ];

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = (data) => {
    if (id) {
      handleSaveBtn(data, id);
    } else {
      handleSaveBtn(data);
    }
  };

  const inputTextStyle =
    "block w-full rounded-md border-0 py-[9px] px-3 text-gray-900 shadow-sm ring-1 ring-gray-300  ring-inset placeholder:text-gray-400 focus:ring-1 focus:ring-primary focus:ring-inset sm:text-sm";

  const errInputText =
    "block w-full rounded-md border-0 py-[9px] px-3 text-gray-900 shadow-sm ring-1 ring-red-300  ring-inset placeholder:text-gray-400 focus:ring-1 focus:ring-red-500 focus:ring-inset sm:text-sm";

  return (
    <>
      <div className="bg-white px-4 pt-5 pb-4 sm:pb-6">
        <div className="sm:flex sm:items-start sm:flex-col">
          <h2 className="text-primary font-medium text-lg">{data.id ? 'Изменить' : 'Добавить'} ЦДО</h2>
          <p className="text-[#6B7280] text-sm mt-1">
            Для {data.id ? 'изменения' : 'добавления'} ЦДО заполните формы
          </p>
          <form
            className="flex flex-col w-full mt-6 gap-6 mb-2"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex items-center gap-6 w-full">
              <div className="flex flex-col gap-1 w-[50%] relative">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-[#374151]"
                >
                  Название
                </label>
                <input
                  type="text"
                  id="name"
                  defaultValue={name}
                  className={errors.name ? errInputText : inputTextStyle}
                  {...register("name")}
                />
                {errors.name && (
                  <p className="text-xs text-red-600 absolute bottom-[-22px]">
                    {errors.name.message}
                  </p>
                )}
              </div>
              <div className="flex flex-col gap-1 w-[50%] relative">
                <label
                  htmlFor="city_id"
                  className="block text-sm font-medium text-[#374151]"
                >
                  Местоположение
                </label>
                <select
                  id="city_id"
                  className={errors.city_id ? errInputText : inputTextStyle}
                  defaultValue={data?.city_name?.id}
                  {...register('city_id')}
                >
                  <option value={location} hidden>{location}</option>
                  {locations
                    .map((item, i) =>
                        <option key={i} value={item.id}>
                          {item.name}
                        </option>
                    )}
                </select>
                {errors.city_id && (
                  <p className="text-xs text-red-600 absolute bottom-[-22px]">
                    {errors.city_id.message}
                  </p>
                )}
              </div>
            </div>
            <div className="flex items-center gap-6 w-full">
              <div className="flex flex-col gap-1 w-[50%] relative">
                <label
                  htmlFor="address"
                  className="block text-sm font-medium text-[#374151]"
                >
                  Адрес
                </label>
                <input
                  type="text"
                  id="address"
                  defaultValue={address}
                  className={errors.address ? errInputText : inputTextStyle}
                  {...register("address")}
                />
                {errors.address && (
                  <p className="text-xs text-red-600 absolute bottom-[-22px]">
                    {errors.address.message}
                  </p>
                )}
              </div>
              <div className="flex flex-col gap-1 w-[50%] relative">
                <label
                  className="block text-sm font-medium text-[#374151]"
                  htmlFor="status"
                >
                  Статус
                </label>
                <select
                  id="status"
                  className={errors.status ? errInputText : inputTextStyle}
                  {...register("status")}
                >
                  <option value={status}>
                    {status === "active" ? "Активный" : "Неактивный"}
                  </option>
                  <option value={status === "active" ? "inactive" : "active"}>
                    {status === "active" ? "Неактивный" : "Активный"}
                  </option>
                </select>
                {errors.status && (
                  <p className="text-xs text-red-600 absolute bottom-[-22px]">
                    {errors.status.message}
                  </p>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="bg-back px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
        <button
          type="submit"
          disabled={loading}
          onClick={handleSubmit(onSubmit)}
          className={loading ? "opacity-50 inline-flex w-full justify-center rounded-md bg-primary px-8 py-[9px] text-sm font-medium text-white hover:bg-secondary sm:ml-3 sm:w-auto disabled:opacity-30" :"opacity-100 inline-flex w-full justify-center rounded-md bg-primary px-8 py-[9px] text-sm font-medium text-white hover:bg-secondary sm:ml-3 sm:w-auto disabled:opacity-30"}
        >
          Сохранить
        </button>
        <button
          onClick={() => setOpen(false)}
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-[#FFFFFF] px-11 py-[9px] text-sm font-medium text-[#374151] ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
        >
          Отмена
        </button>
      </div>
    </>
  );
};
export default CdoForm;
