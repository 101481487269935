import React, { useEffect, useState } from "react";

const LabelInputContainer = ({
  label,
  value,
  setValue,
  parent,
  disabled,
  locationId,
}) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const role = user.role[0];
  const [inputVal, setInputVal] = useState(value.value);


  const submitInput = (number) => {
    setInputVal(number);
    setValue(number, parent, value.id);
  };

  useEffect(() => {
    setInputVal(value.value); 
  }, [value]);
  return (
    <div
      key={locationId}
      className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6"
    >
      <label
        htmlFor="stations"
        className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
      >
        {label}
      </label>
      <div className="mt-2 sm:col-span-2 sm:mt-0">
        {label.includes("Ҳамагӣ") ? (
          <span>{inputVal}</span>
        ) : role === "Республиканский ЦДО" || role === "Администратор" ? (
          <span>{inputVal}</span>
        ) : (
          <input
            onInput={(e) => submitInput(e.target.value)}
            disabled={disabled}
            type="number"
            value={inputVal}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
          />
        )}
      </div>
    </div>
  );
};

export default LabelInputContainer;
