import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

export const kormandonApi=createApi({
    reducerPath: 'kormandonApi',
    tagTypes: 'Kormandon',
    baseQuery: fetchBaseQuery({
        baseUrl: `https://api.upshift.colibri.tj/api/`,
        prepareHeaders: (headers ) => {
            const token = JSON.parse(localStorage.getItem('token'));
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers;
        }
    }),
    endpoints: build => ({
        getKormandon: build.query({
            query:(locationId)=> `report/table2${locationId ? `?cityId=${locationId}` : ''}`
        }),

        postKormandon: build.mutation ({
            query: (data) => ({
                url: `report/table2`,
                method: "POST",
                body: data

            })
        })

    })

})

export const { useGetKormandonQuery, usePostKormandonMutation } = kormandonApi
