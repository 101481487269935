import React from "react";
import { locations } from "../../components/hardCodedArrays/Arrays";

const inputTextStyle =
  "block w-full rounded-md border-0 py-[9px] px-3 text-gray-900 shadow-sm ring-1 ring-gray-300 ring-inset placeholder:text-gray-400 focus:ring-1 focus:ring-primary focus:ring-inset sm:text-sm";

export default function LocationsCP({ locationId, setLocationId }) {
  const user = JSON.parse(localStorage.getItem("user"));
  const role = user.role[0];

  const handleChange = (e) => {
    setLocationId(e.target.value);
  };

  return (
    <>
      {(role === "Администратор" || role === "Республиканский ЦДО") && (
        <div className="mt-8 lg:absolute top-5 right-2">
          <label
            htmlFor="city_id"
            className="block text-sm font-medium text-[#374151]"
          >
            Местоположение
          </label>
          <select
            className={inputTextStyle}
            id="city_id"
            value={locationId}
            onChange={handleChange}
          >
            <option value={""} hidden>
              {""}
            </option>
            {locations.map((item, i) => (
              <option key={i} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      )}
    </>
  );
}
